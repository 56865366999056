@import "../../variables";

#spaces {
    background: #eaebe5;
    font-family: "Philosopher";

    .blog-box {
        padding: 20px;
        max-width: 1100px;
        margin: 0 auto;

        .page-title {
            color: $primary-color;
            font-size: 2.7em;
            margin-left: 55px;
            margin-bottom: 35px;
        }

        .page-subtitle {
            color: $primary-color;
            font-size: 1.7em;
            margin-left: 55px;
            margin-bottom: 35px;
        }
    }

    .header{
        display: flex;
        flex-direction: column;
        margin-left: 55px;
        max-width: 1100px;

        img {
            width: 700px;
            height: 400px;
        }

        h3 {
            color: $primary-dark;
            font-size: 1.5em;
            margin-left: 35px;
            margin-bottom: 35px;
            line-height: 1.5em;
        }
    }

    
}
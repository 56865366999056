@import "../../variables";
.header {
  display: flex;
  align-items: center;
  padding: 40px;
  img {
    width: 300px;
  }
  h1 {
    margin-left: 40px;
    font-size: 2em;
    color: $primary-dark;
  }
  h3 {
    margin-left: 40px;
    font-size: 1.3em;
    color: $primary-color;
    margin-top: 0.5em;
  }
}

@media screen and (max-width: 547px){
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    width: 100%;
    img {
      width: 90%;
    }
  }
}